import React, { useState, useEffect, useRef } from 'react';
import ResponsiveAppBar from './Appbar';
import Preloader from './Preloader';
import LinearProgress, { LinearProgressProps } from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import $ from 'jquery';
import Footer from  './Footer';

const DailyMahabote = () => {
  
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  
  
  useEffect(() => {
    const dob = localStorage.getItem("baydinwebdob");  
    const apiUrl = 'https://us-central1-nfc-project-e3961.cloudfunctions.net/function-1/getDailyMahabote?birthday='+dob;
    fetch(apiUrl)
    .then(response => response.json())
    .then(data => {setData(data);setLoading(false);})
    .catch(error => {console.error('Error fetching data:', error);setLoading(true);
    });   
  }, []);
  
  function LinearProgressWithLabel(
    props: LinearProgressProps & { value: number }
  ) {
    const minuscolor = props.value < 0 ? "error" : "primary"; 
    const progressBarRef = useRef(null);
    
    useEffect(() => {
      const width = `${100}%`;
      $(progressBarRef.current).width(0).animate({ width }, 1500);
    }, []);
    
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
      <div style={{ width: '100%' }} ref={progressBarRef}>
      <LinearProgress 
      variant="determinate"
      value={Math.abs(props.value)} 
      color={minuscolor}
      />
      </div>
      </Box>
      <Box sx={{ minWidth: 35 }}>
      <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
      </Box>
    );
  }
  
  function LinearProgressList({ data }: LinearProgressListProps) {
    const progressBars = Object.entries(data).map(([category, value], index) => {
      const numericValue = parseInt(value);
      
      if (index === 0) {
        return (
          <div key={category}>
          <h5 class="uk-text-bold">{category}</h5>
          <LinearProgressWithLabel value={numericValue} />
          <WorkPercentResult data={data} />
          </div>
        );
      } else if(index === 1) {
        return (
          <div className="uk-margin-top" key={category}>
          <h5 class="uk-text-bold">{category}</h5>
          <LinearProgressWithLabel value={numericValue} />
          <MoneyPercentResult data={data} />
          </div>
        );
      } else if(index === 2) {
        return (
          <div className="uk-margin-top" key={category}>
          <h5 class="uk-text-bold">{category}</h5>
          <LinearProgressWithLabel value={numericValue} />
          <PeacePercentResult data={data} />
          </div>
        );
      }
      else if(index === 3) {
        return (
          <div className="uk-margin-top" key={category}>
          <h5 class="uk-text-bold">{category}</h5>
          <LinearProgressWithLabel value={numericValue} />
          <SocialPercentResult data={data} />
          </div>
        );
      }
      else if(index === 4) {
        return (
          <div className="uk-margin-top" key={category}>
          <h5 class="uk-text-bold">{category}</h5>
          <LinearProgressWithLabel value={numericValue} />
          <FriendPercentResult data={data} />
          </div>
        );
      }
      else if(index === 5) {
        return (
          <div className="uk-margin-top" key={category}>
          <h5 class="uk-text-bold">{category}</h5>
          <LinearProgressWithLabel value={numericValue} />
          <TripPercentResult data={data}  />
          <span className="precentMeaning"></span>
          </div>
        );
      }
      return null; 
    });
    
    return <div>{progressBars}</div>;
  }
  
  function WorkPercentResult({ data }) {
    
    if(data.work === "-100%"){return(<span>အခက်အခဲဆုံး ရင်ဆိုင်ရမှုများ ကြုံတွေ့နိုင်ပါသည်။ လုပ်ငန်းများ လုံးဝမဆောင်ရွက်သင့်ပါ။ အဆင်မပြေမှုများ အနောင့်အယှက်များနှင့်သာ ပြည့်နေပါမည်။</span>)}
    else if (data.work ==="-75%"){return(<span>အလွန်ကြီးမားသော အခက်အခဲများ ကြုံတွေ့နိုင်ပါသည်။ လုပ်ငန်းများ မဆောင်ရွက်သင့်ပါ။</span>);}
    else if (data.work ==="-50%"){return(<span>အခက်အခဲများ ကြုံတွေ့နိုင်ပါသည်။ ချင့်ချိန်ဆောင်ရွက်သင့်ပါသည်။</span>);}
    else if (data.work ==="-25%"){return(<span>အခက်အခဲအနည်းငယ် ကြုံတွေ့နိုင်ပါသည်။</span>);}
    else if (data.work ==="+25%"){return(<span>အနည်းငယ် အဆင်ပြေနိုင်ချေရှိပါသည် ။ ချင့်ချိန်ကာ လုပ်ငန်းဆောင်ရွက်သင့်ပါသည်။</span>);}
    else if (data.work ==="+50%"){return(<span>လွန်စွာ အဆင်ပြေနိုင်ပါသည် ။ လုပ်ငန်းများ တိုးချဲ့ဆောင်ရွက်သင့်ပါသည်။</span>);}
    else if (data.work ==="+75%"){return(<span>အလွန်ကောင်းမွန် ချောမွေ့ အဆင်ပြေနိုင်ပါသည် ။ လုပ်ငန်းများ တိုးချဲ့၊ တိုးမြှင့် ဆောင်ရွက်သင့်ပါသည်။</span>);}
    else if (data.work ==="+100%"){return(<span>ဆောင်ရွက်သမျှ အောင်မြင်မည့် အဆင်ပြေဆုံး ထိပ်တန်းကာလဖြစ်ပါသည် ။ လုပ်ငန်းများ တိုးချဲ့၊ တိုးမြှင့် ဆောင်ရွက်သင့်ပါသည် ။ ရာနှု်နးပြည့်အောင်မြင်မည်ဖြစ်သည်။</span>);}
  }
  
  function MoneyPercentResult({ data }) {
    if (data.money ==="-100%"){return(<span>အကြီးမားဆုံး ဆုံးရှုံးရမှုများ ကြုံတွေ့နိုင်ပါသည် . ရင်းနှီးမြှုပ်နှံမှုများ လုံးဝမဆောင်ရွက်သင့်ပါ။</span>);}
    else if (data.money ==="-75%"){return(<span>ငွေကြေးဆုံးရှုံးမှုနဲ့ ရင်ဆိုင်ရဖို့အတွက် ဖိအားတွေဖြစ်နိုင်တယ်။အခြေအနေကို အကဲဖြတ်ရန် ငြိမ်သက်နေသင့်ပြီး ခြေတစ်လှမ်းဆုတ်ရန် အရေးကြီးသည်။</span>);}
    else if (data.money ==="-50%"){return(<span>ငွေဝင်‌ငွေထွက်မမျှ သဖြင့်ဆုံးရှုံးမှုဖြစ်စေသည့်အရာများကို လျှော့ချရန် သင်၏ဘဏ္ဍာရေးဆိုင်ရာ တစ်ခုလုံးကို စစ်ဆေးကြည့်ရှုခြင်းဖြင့် စတင်ပါ။</span>);}
    else if (data.money ==="-25%"){return(<span>ဘဏ္ဍာရေးအကြံပေးများကဲ့သို့သော အကူအညီများပေးနိုင်သည့် အရင်းအမြစ်များကို ရှာဖွေပါ။ လမ်းကြောင်းမှန်ပေါ်ပြန်ရောက်လုနည်းပါးဖြစ်နေပါသည်။</span>);}
    else if (data.money ==="+25%"){return(<span>အချိန်ပိုင်းအလုပ်တစ်ခုရယူခြင်း သို့မဟုတ် အသုံးစရိတ်ဖြတ်ရန်နည်းလမ်းများရှာဖွေခြင်းကဲ့သို့သော သင့်ဝင်ငွေတိုးရန် အသုံးပြုနိုင်သည့် မည်သည့်နည်းကိုမဆို ထည့်သွင်းစဉ်းစားပါ။အဆင်ပြေခြင်းများစတင်ပါတော့မည်။</span>);}
    else if (data.money ==="+50%"){return(<span>ဝင်ငွေတိုးပြီး အသုံးစရိတ်များ ကျဆင်းလာကာ အမြတ်ငွေနှင့် ငွေသားစီးဆင်းမှု တိုးလာမည်။</span>);}
    else if (data.money ==="+75%"){return(<span>အသားတင်အမြတ်ငွေမှာ ယခင်ထက်သိသိသာသာတိုးလာမည် ဖြစ်သည်။</span>);}
    else if (data.money ==="+100%"){return(<span>ငွေကြေးအရ အောင်မြင်ဆုံးအချိန်ဖြစ်သည်။ ရင်းနှီးမြှုပ်နှံသမျှ အမြတ်စွန်းများစွာနှင့် စုဆောင်းနိုင်လိမ့်မည်။</span>);}
  }
  
  function PeacePercentResult({ data }) {
    if (data.peace === "-100%") {return (<span>အခက်ခဲဆုံး ပြသနာများ ကြုံဆုံရမည့် ကာလ ဖြစ်ပါသည်။ဘာလုပ်လုပ် စိတ်ဖိစီးမှုများနှင့်သာ တွေ့ကြုံရမည်။</span>);}
    else if (data.peace === "-75%") {return (<span>ခက်ခဲသောစကားဝိုင်းများနှင့် ကြမ်းတမ်းသောဆုံးဖြတ်ချက်များကို ကျွန်ုပ်တို့ရှောင်လွှဲ၍မရကြောင်း အသိအမှတ်ပြုပါ။ပြသနာများ စိန်ခေါ်မှုများကိုတွေ့ကြုံရလိမ့်မည်။</span>);}
    else if (data.peace === "-50%") {return (<span>အနှောင့်အယှက်များနှင့် ခက်ခဲမှုအဆင့်များကို ဖြတ်ကျော်နေရဦးမည်။ စိတ်ချမ်းသာစရာကိစ္စများတစ်လှည့် အခက်ခဲများတစ်လှည့်နှင့်သံသရာလည်နေမည်ဖြစ်သည်။</span>);}
    else if (data.peace === "-25%") {return (<span>ပြသနာ အနည်းငယ် ကြုံတွေ့နိုင်ပါသည်။အေးချမ်းမှုများကို မကြာမီစတင်ခံစားရပါတော့မည်။</span>); }
    else if (data.peace === "+25%") {return (<span>သင်၏ပြသနာများကို စီမံခန့်ခွဲနိုင်သော အလုပ်များအဖြစ် ခွဲခြမ်းစိပ်ဖြာပြီး လိုအပ်ပါက အကူအညီတောင်းရန် မကြောက်ပါနှင့်။</span>);}
    else if (data.peace === "+50%") {return (<span> အတွင်းစိတ်ငြိမ်းချမ်းမှုနှင့် ငြိမ်သက်ခြင်း၏ အာရုံခံစားမှုကို သင်စတင်တွေ့ရှိလာမည်ဖြစ်သည်။</span>);}
    else if (data.peace === "+75%") {return (<span>တည်ငြိမ်အေးချမ်းတဲ့ ခံစားချက်တွေနဲ့ လက်တွဲဖို့ အချိန်တန်ပါပြီ။ပိုမိုငြိမ်းချမ်းပြီး သာယာဝပြောသော အနာဂတ်ကို ဖန်တီးနိုင်ပါတော့မည်။</span>);}
    else if (data.peace === "+100%") {return (<span>အလုံးစုံတည်ငြိမ်အေးချမ်းမှု့ အပေါင်းကို ရရှိပိုင်နိုင်နေရပါမည်။ အရာရာသည် သင့်အတွက် အဆင်ပြေနေပါလိမ့်မည်။</span>);}
  }
  
  function SocialPercentResult({ data }) {
    if (data.social === "-100%") {return (<span>ကတိပျက်သူများ၊ နောက်ကျောဓားနှင့်ထိုးမည့်သူများ၊ ဒုက္ခပေးသူများသာ ဝန်းရံနေမည့်ကာလဖြစ်သည်။ မည်သူ့ကိုမျှမယုံပါနှင့်။ ကိုယ်လုပ်မည့်ကိစ္စရပ်များကိုလည်း လူသိမခံပါနှင့်။</span>);}
    else if (data.social === "-75%") {return (<span>သင်ပိုမိုဆိုးရွားသောပြဿနာများဖြစ်ပေါ်စေနိုင်သည့် လုပ်ဖော်ကိုင်ဖက်များ သို့မဟုတ် ဖောက်သည်များနှင့် သဘောထားကွဲလွဲမှုများ ကြုံတွေ့ရနိုင်သည်။</span>);}
    else if (data.social === "-50%") {return (<span>အပြောနှင့်အလုပ် မညီသောသူများ၊ မိမိအား မနာလိုသူများရှိသည့်အသိုင်းအဝိုင်းကြားထဲရောက်နေပေလိမ့်မည်။</span>);}
    else if (data.social === "-25%") {return (<span>မျက်နှာနှစ်ခြမ်းရှိသော လုပ်ဖော်ကိုင်ဖက်၊ လှည့်စားသော စီးပွားရေးလုပ်ဖော်ကိုင်ဖက်၊ မယုံကြည်ထိုက်သော အိမ်နီးချင်းများကို သတိထားဆက်ဆံပါ။</span>); }
    else if (data.social === "+25%") {return (<span>အပေါင်းအသင်းများ လွယ်ကူစွာ ပေါင်းသင်းနိုင်ပြီး အခြားသူများ၏ နှစ်သက်ခြင်းကို ခံရမည်။အဆက်အသွယ်သစ်များရရှိတတ်သည်။</span>);}
    else if (data.social === "+50%") {return (<span> ဖော်ရွေသော လူမှု့ဆက်ဆံရေးများနှင့်ကြုံရလိမ့်မည်။ စိတ်ကူးသစ်များကို ဖွင့်ပေးနိုင်မည့်သူများနှင့် တွေ့ကြုံဆုံတွေ့ရမည်။</span>);}
    else if (data.social === "+75%") {return (<span>အဖြေရှာရန်လက်တွဲလုပ်ဆောင် ပူးပေါင်းမည့်သူများနှင့်တွေ့ရမည်။အတူတကွဖြေရှင်းပြီး ကိစ္စရပ်များကိုအောင်မြင်စေရန် ပံ့ပိုးပေးမည့်သူများဖြစ်သည်။</span>);}
    else if (data.social === "+100%") {return (<span>သင့်အား အကောင်းဆုံးဖြစ်အောင် လှုံ့ဆော်ပေးပြီး သင့်ပန်းတိုင်ကို ရောက်အောင် ကူညီပေးမည့်သူများနှင့်တွေ့ဆုံရမည်။</span>);}
  }
  
  function  FriendPercentResult({ data }) {
    if (data.friend === "-100%") {return (<span>သူငယ်ချင်း၊မိတ်ဆွေများနှင့် စကားများ ရန်ဖြစ်တတ်သည်။ အထင်လွဲ စွတ်စွဲ ခံရမည့်ကိစ္စများဖြစ်ပေါ်လာနိုင်သည်။</span>);}
    else if (data.friend === "-75%") {return (<span>သူငယ်ချင်းများနှင့် အငြင်းအခုံ စကားများရကိန်းရှိမည်။ အခင်အမင်ပျက်သည်အထိပင် ဖြစ်နိုင်​သည်။</span>);}
    else if (data.friend === "-50%") {return (<span>တစ်စုံတစ်ဦးကို နားလည်ရန် အခက်အခဲရှိနိုင်သည် သို့မဟုတ် သင့်စိတ်ကူးများကိုထိထိရောက်ရောက်ဆက်သွယ်ပြောဆိုနိုင်ခြင်း မရှိပေ။</span>);}
    else if (data.friend === "-25%") {return (<span>တစ်ကိုယ်ကောင်းဆန်သော သူများနှင့်တွေ့ပြီး အတင်းပြောခံရတတ်သည်။</span>); }
    else if (data.friend === "+25%") {return (<span>မကောင်းသော မိတ်ဆွေများသင့်ထံမှ ထွက်ခွာသွားမည်ဖြစ်ပြီး မိတ်ဆွေစစ် သငယ်ချင်းကောင်းများနှင့် တွေ့ရမည်ဖြစ်သည်။</span>);}
    else if (data.friend === "+50%") {return (<span>ဝေးကွာနေသော သူငယ်ချင်းများနှင့်ပြန်လည် ဆုံစည်းရတတ်သည်။ ပျော်ရွှင် ရယ်မောဖွယ်ကောင်းသောအချိန်များကိုသူငယ်ချင်းများနှင့်အတူကုန်ဆုံးရမည်။</span>);}
    else if (data.friend === "+75%") {return (<span>အချိန်၊ အရင်းအမြစ်များနှင့် ခွန်အားများဖြင့် ရက်ရောစွာ ကူညီပံ့ပိုးပေးလိုစိတ်ရှိကြသည့် မိတ်ဆွေများနှင့်တွေ့ဆုံရမည်။</span>);}
    else if (data.friend === "+100%") {return (<span>ကံကောင်းစေသော သူငယ်ချင်းများသည် တွေ့ဆုံသူတိုင်းအတွက် နေရောင်ခြည်နှင့် ပျော်ရွှင်မှုတို့ကို ယူဆောင်လာကြသည်။သင့်သူငယ်ချင်းမိတ်ဆွေကြောင့် ယနေ့ပျော်ရွှင်နေရပေလိမ့်မည်။</span>);}
  }
  
  function TripPercentResult({ data }) {  
    if (data.trip === "-100%") {return (<span>စီစဉ်ထားသမျှ ခရီးများပျက်မည်။ ဇွတ်သွားရန် ကြိုးစားပါကလည်း လမ်းခရီးတွင် အနှောက်အယှက်များ အန္တရာယ်များနှင့်တွေ့ကြုံရလိမ့်မည်။</span>);}
    else if (data.trip === "-75%") {return (<span>ခရီးသွားလျှင် အနှောက်အယှက်များ အပျောက်အရှများ ဖြစ်ပေါ်တတ်သည်။ လိုရာခရီးသို့ရောက်ရှိရန် သတိကြီးစွာထား၍ သွားလာပါ။</span>);}
    else if (data.trip === "-50%") {return (<span>ခရီးတစ်ခု စတင်မည်ဆိုပါက သင့်ပတ်ဝန်းကျင်ကို သတိထားရန် အမြဲတမ်း အရေးကြီးပြီး သင့်အတွက် ဖြစ်ပေါ်လာနိုင်သည့် အန္တရာယ်များအတွက် ကြိုတင်ပြင်ဆင်ထားပါ။ သင့်ခရီးတွင် မမျှော်လင့်သောအနှောက်အယှက်များ အစီအစဉ်လွဲမှားခြင်းများကြုံရနိုင်သည်။</span>);}
    else if (data.trip === "-25%") {return (<span>ကြိုတင်သေချာပြင်ဆင်ပြီး လိုက်လျောညီထွေဖြစ်အောင်နေပါ။ ခရီးတွင် သဘောထားမတိုက်ဆိုင်သူများနှင့်ကြုံတွေ့ရတတ်သည်။</span>); }
    else if (data.trip === "+25%") {return (<span>သင်ဘယ်ကိုသွားနေပါစေ၊ ခရီးသွားကံအနည်းငယ်ရှိခြင်းက အရာအားလုံးကို ကွဲပြားသွားစေနိုင်ပါတယ်။ ဒါကြောင့် အပြုသဘောဆောင်ဖို့ မမေ့ပါနဲ့၊ ကြိုပြီးစီစဉ်ပါ၊ စိတ်ကိုဖွင့်ထားပါ၊ အခက်အခဲအနည်းငယ်ရှိလင့်ကစား ခရီးစဉ်ကောင်းတစ်ခုရမယ်ဆိုတာ သေချာပါတယ်။</span>);}
    else if (data.trip === "+50%") {return (<span>သာယာပြီး ဘေးကင်းသော ခရီးတစ်ခုရရှိခြင်းကဲ့သို့ ရိုးရှင်းနိုင်သည်။</span>);}
    else if (data.trip === "+75%") {return (<span>ခရီးသွားရင်း ပျော်ရွှင်စရာများတွေ့ကြုံနိုင်ပြီး ခရီးစဉ်တလျှောက် အန္တရာယ်ကင်းစွာနှင့် လိုရာခရီးသို့ချောမောစွာရောက်ရှိပေလိမ့်မည်။</span>);}
    else if (data.trip === "+100%") {return (<span>လိုချင်သောအတွေ့အကြုံများနှင့် ရလဒ်များအားလုံးနှင့်အတူ တစ်ဦး၏ဦးတည်ရာသို့ အလွယ်တကူရောက်ရှိပါလိမ့်မည်။</span>);}
    
  }
  
  return (
    <div>
    {isLoading ? (
      <Preloader />
    ) : (
      <div style={{"margin":"100px"}}>
      
      <h4 className='uk-margin-top uk-text-bold'> Daily Mahabote Reading</h4>
      <LinearProgressList data={data} />
      
      
      </div>
    )}
    </div>
  );
};


export default function DailyMahaboteReading() {
  return (
    <div>
    <ResponsiveAppBar />
    <DailyMahabote />
    <Footer/>
    </div>
  );
}
